<template>
  <div class="pay-dialog">
    <van-dialog v-model="visible" :showConfirmButton="false" style="background-color: transparent;">
      <div class="wrapper">
        <p>支付是否成功</p>
        <p>请确认支付状态，领取话费券</p>
        <div>
          <van-button plain round @click="handleReload">支付失败</van-button>
          <van-button round @click="handleReload">支付成功</van-button>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>

import Vue from 'vue'
import { Dialog, Button } from 'vant'

Vue.use(Button)
Vue.use(Dialog)

export default {
  name: 'payDialog',
  data () {
    return {
      visible: true
    }
  },
  methods: {
    /*
    支付成功刷新页面
    */
    handleReload () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.pay-dialog {
  .wrapper{
    position: relative;
    display: inline-block;
    width: 320px;
    height: 180px;
    background: #FFFFFF;
    border-radius: 16px;
    text-align: center;
    p:first-child{
      margin-top: 24px;
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
    }
    p{
      font-size: 15px;
      color: #7F694E;
      line-height: 21px;
    }
    & > div{
      margin-top: 20px;
      .van-button{
        width: 136px;
        height: 50px;
        border-radius: 25px;
        font-size: 18px;
        color: #7F694E;
        line-height: 25px;
      }
      .van-button:first-child{
        margin-right: 16px;
        border: 1px solid #7F694E;
      }
      .van-button:last-child{
        background: linear-gradient(180deg, #F74545 0%, #D92635 100%);
        font-weight: bold;
        color: #FFFFFF;
        border: none;
      }
    }
  }
}
</style>
